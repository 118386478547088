import React from 'react';

export const useClickOutside = <T,>(
  initialValue: T | null,
  callback?: () => void,
  eventType = 'mousedown',
) => {
  const [isVisible, setIsVisible] = React.useState<T | null>(initialValue);
  const ref = React.useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(null);
      callback?.();
    }
  };

  React.useLayoutEffect(() => {
    document.addEventListener(eventType, handleClickOutside);

    return () => {
      document.removeEventListener(eventType, handleClickOutside);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};
