import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Plus } from '@images/plus.svg';
import { ReactComponent as Everything } from '@images/everything.svg';
import { track } from '@amplitude/analytics-browser';
import { createWhiteboard } from '@app/services/whiteboard.service';
import { createDocument } from '@app/services/document.service';
import { useUnitsContext } from '@app/context/unitsContext/unitsContext';
import ComingSoon from '@widgets/components/ComingSoon';
import CreateChannel from '@widgets/components/Modals/CreateChannel/CreateChannel';
import { Unit, UnitType } from '@entities/models/unit';
import Tooltip from '@shared/uikit/tooltip';
import IconButton from '@shared/uikit/icon-button';

import UnitsDndContext from './ui/units-dnd/units-dnd-context';
import UnitElement from './ui/unit-element';
import UnitDndDragOverlay from './ui/units-dnd/units-dnd-drag-overlay';
import UnitsDndElement from './ui/units-dnd/units-dnd-element';
import UnitsDndSideEffects from './ui/units-dnd/units-dnd-side-effects';
import { DragEndEvent } from '@dnd-kit/core';
import { calculateNodeIndent } from './utils/calculate-node-indent';
import { dndUnitGetter } from './utils/dnd-unit-getter';
import styles from './style.module.scss';
import { useChangeUnitOrder } from '@app/queries/workspace/unit/useChangeUnitOrder';
import { useWorkspaceContext } from '@app/context/workspaceContext/workspaceContext';
import { moveUnit } from './utils/move-unit';
import { traverseTreeInDepth } from './utils/traverse-tree-in-depth';
import { useDispatch } from 'react-redux';
import { setUnits } from '@app/redux/features/unitsSlice';
import { TreeNode, TreeNodeMetadata } from './types';
import { cn } from '@app/utils/cn';

const buildUnitsTree = function buildTree(
  units: Unit[],
  _parentNode?: TreeNode | null,
  _level = 0,
): TreeNode[] {
  const sordtedUnits = [...units].sort((a, b) => a.order - b.order);
  const childs = _parentNode
    ? sordtedUnits.filter(
        (unit) =>
          unit.parentUnit?.id === _parentNode.id && unit.type !== 'task_board',
      )
    : sordtedUnits.filter(
        (unit) => unit.parentUnit === null && unit.type !== 'task_board',
      );

  const level = (_level += 1);
  return childs.map((unit: Unit) => {
    const node: TreeNode = {
      id: unit.id,
      name: unit.name,
      metadata: {
        type: unit.type,
        name: unit.name,
        id: unit.id,
        order: unit.order,
        color: unit.color,
        hasChildren: false,
        level,
        parent: _parentNode ?? null,
      },
      children: [],
    };
    const children = buildTree(units, node, level);

    node.children = children;
    if (node.metadata) node.metadata.hasChildren = children.length > 0;
    return node;
  });
};

const SidebarUnitsPanel = () => {
  const { units, addUnit } = useUnitsContext();
  const { documentId } = useParams();
  const { workspace } = useWorkspaceContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCreateChannelModalOpen, setCreateChannelModalState] =
    useState<boolean>(false);
  const activeUnit = units.find((unit) => unit.id === documentId);

  const unitsTree = useMemo(() => buildUnitsTree(units), [units]);
  const unitsFaltten = useMemo(
    // @ts-ignore Lack of boolean type in IFlatMetadata interface
    () => flattenTree<TreeNodeMetadata>({ name: '', children: unitsTree }),
    [unitsTree],
  );

  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { changeUnitsOrder } = useChangeUnitOrder();

  const validExpandedIds = useMemo(() => {
    const idsSet = new Set(expandedIds);
    return unitsFaltten.reduce<string[]>((acc, unit) => {
      const unitId = unit.id.toString();
      if (idsSet.has(unitId)) acc.push(unitId);
      return acc;
    }, []);
  }, [unitsFaltten, expandedIds]);

  const openUnit = (id: string) => {
    navigate(`/workspace/${id}`);
  };

  const handleCreateChannel = () => {
    track('create_channel_popup_opened');
    setCreateChannelModalState(true);
  };

  const handleExpand = useCallback((isExpanded: boolean, elementId: string) => {
    setExpandedIds((ids) => {
      const idsSet = new Set(ids);
      if (isExpanded) idsSet.add(elementId);
      else idsSet.delete(elementId);
      return Array.from(idsSet);
    });
  }, []);

  const handleDragEnd = async (
    event: DragEndEvent,
    { canInsert }: { canInsert: boolean },
  ) => {
    if (!canInsert) return;
    const activeUnit = dndUnitGetter<TreeNodeMetadata>(event.active);
    const overUnit = dndUnitGetter<TreeNodeMetadata>(event.over);
    const newParent = overUnit?.parent;

    if (
      !activeUnit ||
      !workspace ||
      !newParent ||
      overUnit.id === activeUnit.id
    )
      return;

    const newOrder = moveUnit(
      newParent.children.map((child) => child.metadata!),
      activeUnit,
      overUnit,
      event.delta.y,
    );

    const newOrderMap = newOrder.reduce<Map<string, number>>(
      (acc, order) => acc.set(order.id, order.order),
      new Map<string, number>(),
    );

    const newUnits = units.map((unit) => {
      const order = newOrderMap.get(unit.id);
      if (order === undefined) return unit;
      return { ...unit, parentUnit: { id: newParent.id }, order };
    });

    dispatch(setUnits(newUnits));

    await changeUnitsOrder({
      workspaceId: workspace.id,
      unitpParentId: newParent.id,
      unitOrders: newOrder,
    });
  };

  const createUnit = async (unit: {
    type: 'document' | 'whiteboard';
    parentId: string;
  }) => {
    const newUnit = await getCreateQuery(unit.type, unit.parentId, uuidv4());
    if (newUnit) {
      await addUnit(newUnit);
      openUnit(newUnit.id);
    }
  };

  const getCreateQuery = (type: UnitType, parentId: string, id: string) => {
    switch (type) {
      case 'document':
        return createDocument(parentId, id);
      case 'whiteboard':
        return createWhiteboard(parentId, id);
      default:
        return createDocument(parentId, id);
    }
  };

  useEffect(() => {
    traverseTreeInDepth(unitsTree, (node, stackTrace, breakLoop) => {
      if (node.id !== documentId) return;
      setExpandedIds((ids) => {
        const idsSet = new Set(ids);
        stackTrace.forEach((element) => {
          if (element.id) idsSet.add(element.id);
        });
        return Array.from(idsSet);
      });
      breakLoop();
    });
  }, [unitsTree, documentId]);

  useEffect(() => {
    if (!activeUnit) return;
    setSelectedIds([activeUnit.id]);
  }, [activeUnit]);

  return (
    <div className={styles.sidebarUnitsPanel}>
      <div className={styles.sidebarUnitsPanelRow}>
        <span className={styles.sidebarUnitsPanelChannelTitle}>Channels</span>
        <Tooltip
          placement='right'
          content='Create a Channel'
        >
          <IconButton
            aria-label='Create a channel'
            onClick={handleCreateChannel}
          >
            <Plus />
          </IconButton>
        </Tooltip>
        <CreateChannel
          handleClose={() => {
            track('channel_create_closed');
            setCreateChannelModalState(false);
          }}
          isOpen={isCreateChannelModalOpen}
        />
      </div>
      <div className={styles.sidebarUnitsPanelRowDisable}>
        <Everything />
        Everything
        <ComingSoon right={40} />
      </div>
      <UnitsDndContext
        // @ts-ignore Lack of boolean type in IFlatMetadata interface
        data={unitsFaltten}
        onDragEnd={handleDragEnd}
      >
        {({
          activeUnit,
          overUnit,
          parentUnit,
          disabled,
          canInsert,
          readonly,
        }) => (
          <>
            <TreeView
              // @ts-ignore Lack of boolean type in IFlatMetadata interface
              data={unitsFaltten}
              className={styles.sidebarUnitsPanelTree}
              selectedIds={selectedIds}
              expandedIds={validExpandedIds}
              clickAction='FOCUS'
              nodeRenderer={({
                element,
                getNodeProps,
                isExpanded,
                isBranch,
              }) => {
                const { onClick: _, ...nodeProps } = getNodeProps();
                const unit = element.metadata as unknown as TreeNodeMetadata;

                return (
                  <div {...nodeProps}>
                    <UnitsDndElement
                      style={{
                        paddingLeft: calculateNodeIndent(
                          unit.id === activeUnit?.id &&
                            overUnit &&
                            !disabled.has(overUnit.id)
                            ? overUnit.level
                            : unit.level,
                        ),
                      }}
                      readonly={readonly.has(unit.id)}
                      disabled={disabled.has(unit.id)}
                      unit={unit}
                      className={cn(
                        parentUnit?.id === element.id && canInsert
                          ? 'border-purple'
                          : 'border-transparent',
                        'transition-all border-solid border',
                      )}
                    >
                      <UnitElement
                        className={cn({
                          'opacity-70': activeUnit?.id === element.id,
                          'opacity-100': activeUnit?.id !== element.id,
                          'opacity-30': disabled.has(element.id),
                        })}
                        showExpandCollapseButton={isBranch}
                        onExpand={handleExpand}
                        onCreateUnit={createUnit}
                        isExpanded={isExpanded}
                        unit={unit}
                        isActive={element.id === documentId}
                      />
                    </UnitsDndElement>
                  </div>
                );
              }}
            />
            <UnitDndDragOverlay
              unit={activeUnit}
              style={{
                paddingLeft: activeUnit
                  ? calculateNodeIndent(activeUnit.level)
                  : undefined,
              }}
              isActive={documentId === activeUnit?.id}
            />
            <UnitsDndSideEffects
              expanded={expandedIds}
              overUnit={overUnit}
              activeUnit={activeUnit}
              onExpand={handleExpand}
              onExpandSet={setExpandedIds}
            />
          </>
        )}
      </UnitsDndContext>
    </div>
  );
};

export default SidebarUnitsPanel;
