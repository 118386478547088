import { UnitType } from '@entities/models/unit';
import { ReactComponent as DocumentIcon } from '@images/document.svg';
import { ReactComponent as WhiteboardIcon } from '@images/whiteboard.svg';
import ColoredIcon from '../ui/colored-icon';

export function getUnitTypeIcon<
  U extends { type: UnitType; name: string; color: string | null },
>(unit: U) {
  switch (unit.type) {
    case 'document':
      return <DocumentIcon />;
    case 'channel':
      return (
        <ColoredIcon
          letter={unit.name[0].toUpperCase()}
          color={unit.color}
        />
      );
    case 'whiteboard':
      return <WhiteboardIcon />;
    default:
      throw new Error(`Unexpected unit type ${unit.type}`);
  }
}
